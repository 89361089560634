<template>
    <div class="main-layout">
        <header>
            <div class="row">
                <div class="col-4 m-col-2">
                    <a href="/"><img src="/static/img/logo.png" alt="Sikkens" class="logo" /></a>
                </div>
                <div class="col-8 m-col-10">
                    <nav :class="{ open: navOpen }">
                        <a href="#" class="burger" @click="navOpen = !navOpen">&#x2630;</a>
                        <ul>
                            <li><router-link to="/">Home</router-link></li>
                            <li><router-link to="/consument">Consument</router-link></li>
                            <li><router-link to="/professional">Vakschilder</router-link></li>
                            <li><a href="https://sikkens.nl" target="_blank">sikkens.nl</a></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </header>
        <div class="content">
            <slot></slot>
        </div>
        <footer>
            <a href="https://www.sikkens.nl" target="_blank"><img src="/static/img/logo.png" alt=""></a>
            <div class="footer-content">
                <ul>
                    <li><a href="https://www.sikkens.nl/nl/gebruiksvoorwaarden" target="_blank">Gebruiksvoorwaarden</a></li>
                    <li><a href="https://www.sikkens.nl/nl/cookiebeleid" target="_blank">Cookiebeleid</a></li>
                    <li><a href="https://www.sikkens.nl/nl/privacy-statement" target="_blank">Privacybeleid</a></li>
                    <!-- li><a href="" target="_blank">Cookie-instellingen</a></li -->
                </ul>
                <div class="row">
                    <div class="col-12">
                        <p>Copyright &copy; 2021-2022 Akzo Nobel N.V.</p>
                        <img src="/static/img/akzo-nobel.png" alt="" class="akzonobel">
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<style lang="scss" scoped>
    @import "base/settings";
    .logo {
        width: 180px;
        margin: 0;
        margin-bottom: 0.5em;
        margin-top: 1.5em;
    }
    header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 0 10px;
        @media ( min-width: $medium )
        {
            padding: 0 30px;
        }
    }
    nav {
        font-family: Roboto;
        text-transform: uppercase;
        text-align: right;
        margin: 0.5em;
        .burger {
            text-decoration: none;
            color: $blue;
        }
        ul {
            transform: translateX( 100vw );
            background: rgba( 255, 255, 255, 0.9 );
            padding: 1em;
            transition: transform 1s ease-in-out;

            li {
                padding: 0.5em;
                width: 100%;
                cursor: pointer;
                margin: 0;
            }
        }
        &.open ul {
            transition: transform 1s ease-in-out;
            transform: translateX( 0 );
            }
        li {
            display: inline-block;
            margin-left: 2em;
            a.active, a:hover {
                border-bottom: 2px $blue solid;
            }
            a {
                text-decoration: none;
                color: $blue;
            }
        }
    }
    footer {
        padding:  15px;

        @media (max-width: $small) {
            .onDesktop {
                display: none;
            }

            img {
                width: 100px;
                height: auto;
            }
            .footer-content {
                padding-left: 7px;

                p {
                    margin: 0;
                }

                // Extra div to override pre-set.
                div div img {
                    height: auto;
                    width: 100px;
                }

                ul {
                    li {
                        display: block;
                        line-height: 35px;
                    }
                }
            }
        }

        @media (min-width: $small) {
            .onMobile {
                display: none;
            }

            ul {
                width: 50%;
                margin-left: auto;
                margin-right: auto;
                text-align: center;

                li {
                    padding: 0 20px;
                    display: inline-block;

                    &:not(:last-child) {
                        border-right: 1px solid #A2A392;
                    }
                }
            }

            text-align: center;
        }

        ul {
            padding-left: 0;

            li, a {
                color: #A2A392;
            }
        }

        img {
            width: 150px;
            height: auto;
        }

        .footer-content {
            p, img {
                display: inline-block;
            }

            img {
                width: 85px;
                height: auto;
            }

            p {
                display: inline-block;
            }
        }

        margin: 0 5px;
    }
    @media (min-width: $small )
    {
        nav {
            ul {
                transform: none;
                background: transparent;
                padding: 0;
                li {
                    width: auto;
                    margin-left: 2em;
                }
            }
            .burger {
                display: none;
            }
        }

        .footer-content img {
            margin-left: 1.563rem;
        }
    }
</style>

<script>
    export default {
        data()
        {
            return {
                navOpen: false
            };
        }
    };
</script>
